import React, { useEffect, useRef, useContext } from "react";
import { useLocation } from "react-router-dom";
import classNames from "classnames";
import UseAnimations from "react-useanimations";
import menu3 from "react-useanimations/lib/menu3";
import { useTranslation } from "react-i18next";
import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll,
} from "body-scroll-lock";
import AnimatedLink from "components/shared/AnimatedLink";
import LanguageSwitcher from "components/shared/LanguageSwitcher";
import MobileMenuContext from "context/MobileMenuContext";
import PlayIcon from "images/play.svg";
import utils from "common/utils";
import pathNames from "router/path-names";
import DonationBtn from "../DonationBtn";

const HeaderMenu = () => {
  const { t } = useTranslation("header");
  const menu = useRef();
  const hamburgerIcon = useRef();
  const location = useLocation();
  const lang = utils.getLang();
  const { mobileMenu, setMobileMenu } = useContext(MobileMenuContext);

  const toggleMenu = () => {
    setMobileMenu(!mobileMenu);

    if (mobileMenu) {
      enableBodyScroll(menu.current);
    } else {
      disableBodyScroll(menu.current);
    }
  };

  useEffect(
    () => () => {
      clearAllBodyScrollLocks();
    },
    []
  );

  // Close menu when route changes
  useEffect(() => {
    if (mobileMenu) {
      hamburgerIcon.current.click();
    }
  }, [location]);

  return (
    <React.Fragment>
      <div
        className={classNames(
          "sf-menu d-flex flex-column justify-content-between flex-lg-grow-1",
          { "sf-menu--visible": mobileMenu }
        )}
        ref={menu}
      >
        <ul className="sf-menu__list list-unstyled mb-0 pl-3 pl-lg-0 text-lg-right align-items-lg-center">
          <li className="sf-menu__list__item d-lg-inline-flex">
            <AnimatedLink to={pathNames.recommendations[lang]}>
              {t("header:safeRecommendations")}
            </AnimatedLink>
          </li>
          <li className="sf-menu__list__item d-lg-inline-flex">
            <AnimatedLink to={pathNames.resources[lang]}>
              {t("header:resources")}
            </AnimatedLink>
          </li>
          <li className="sf-menu__list__item d-lg-inline-flex">
            <AnimatedLink to={pathNames.faq[lang]}>
              {t("header:frequentlyAskedQuestions")}
            </AnimatedLink>
          </li>
          <li className="sf-menu__list__item d-lg-none">
            <AnimatedLink to={pathNames.exercises[lang]}>
              {t("header:startExercising")}
            </AnimatedLink>
          </li>
          <li className="sf-menu__list__item d-none d-lg-inline-flex">
            <AnimatedLink to={pathNames.exercises[lang]} type="button">
              {t("header:startExercising")}
              <img src={PlayIcon} className="sf-menu__play-icon" alt="" />
            </AnimatedLink>
          </li>
        </ul>
        <div className="sf-menu__btn-wrapper d-flex d-lg-none justify-content-between px-3 pb-4">
          <div className="row w-100 align-items-end">
            <div className="col-5">
              <div className="sf-contact-btn mb-4">
                <AnimatedLink to={pathNames.contact[lang]}>
                  {t("header:contact")}
                </AnimatedLink>
              </div>
              <LanguageSwitcher classes="p-0" />
            </div>
            <div className="col-7 pr-0 text-right">
              <DonationBtn />
            </div>
          </div>
        </div>
        <div className="sf-menu__triangle position-absolute d-lg-none" />
      </div>
      <UseAnimations
        animation={menu3}
        size={72}
        speed={2}
        reverse={mobileMenu}
        onClick={toggleMenu}
        render={(eventProps, animationProps) => (
          <button
            ref={hamburgerIcon}
            className="sf-hamburger position-absolute btn bg-primary border-0 rounded-0 p-0 d-lg-none"
            type="button"
            aria-label="Open/Close Menu"
            {...eventProps}
          >
            <div className="sf-hamburger__container" {...animationProps} />
          </button>
        )}
      />
    </React.Fragment>
  );
};

export default HeaderMenu;

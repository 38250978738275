import React, { useEffect, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import Faq from "components/faq/faq";
import Intro from "components/shared/Intro";
import ResponsiveImage from "components/shared/ResponsiveImage";
import Title from "components/shared/Title";
import TriangleContainer from "components/shared/TriangleContainer";
import Seo from "components/shared/Seo";
import utils from "common/utils";
import FaqImgMobile1X from "images/faq-mobile.webp";
import FaqImgMobile2X from "images/faq-mobile@2x.webp";
import FaqImgTablet1X from "images/faq-tablet.webp";
import FaqImgTablet2X from "images/faq-tablet@2x.webp";
import FaqImgDesktop1X from "images/faq-desktop.webp";
import FaqImgDesktop2X from "images/faq-desktop@2x.webp";
import metaImageUrl from "images/faq-tablet.jpg";

const images = [
  {
    media: "all and (max-width: 480px)",
    img: FaqImgMobile1X,
    img2x: FaqImgMobile2X,
    type: "image/webp",
  },
  {
    media: "all and (max-width: 768px)",
    img: FaqImgTablet1X,
    img2x: FaqImgTablet2X,
    type: "image/webp",
  },
  {
    media: "all and (min-width: 769px)",
    img: FaqImgDesktop1X,
    img2x: FaqImgDesktop2X,
    type: "image/webp",
  },
];

const faqItems = [
  { id: 1, title: "faqs.1.title", body: "faqs.1.body" },
  { id: 2, title: "faqs.2.title", body: "faqs.2.body" },
  { id: 3, title: "faqs.3.title", body: "faqs.3.body" },
  { id: 4, title: "faqs.4.title", body: "faqs.4.body" },
  { id: 5, title: "faqs.5.title", body: "faqs.5.body" },
  { id: 6, title: "faqs.6.title", body: "faqs.6.body" },
  { id: 7, title: "faqs.7.title", body: "faqs.7.body" },
  { id: 8, title: "faqs.8.title", body: "faqs.8.body" },
  { id: 9, title: "faqs.9.title", body: "faqs.9.body" },
  { id: 10, title: "faqs.10.title", body: "faqs.10.body" },
];

const metaImage = {
  url: utils.getImageFullPath(metaImageUrl),
  width: "737",
  height: "551",
};

const Faqs = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation("faq");

  const media = <ResponsiveImage images={images} altText={t("pageTitle")} />;

  const text = (
    <p>
      <Trans i18nKey="intro.text" ns="faq" components={{ bold: <strong /> }} />
    </p>
  );

  const [faqsData, setFaqsData] = useState([]);

  useEffect(() => {
    const fetchFaqs = async () => {
      const response = await fetch(
        `https://cms.safe-seniors.com/api/faq?populate=*&locale=${language}`,
        {
          headers: {
            Authorization: `Bearer 9babcd4fc3c0dc773b4f694360f4ba37e113f6fee3c3b2fdbd839beeb52924483cec1e1a36d249758542648558e8bb40f58579ef06482808e47d22a86f8d114ac03f2a66790e9d129f886772058cc393334d27399ae0aebcf9ea86ea90dc9785b7695b56d891cd1b7e700a7ff0eb8e4cb0ad766fea64c48738286dfd551a459e`,
          },
        }
      );
      const data = await response.json();
      setFaqsData(data);
    };
    fetchFaqs();
  }, [language]);

  const data = faqsData?.data;

  return (
    <React.Fragment>
      <Seo
        title={t("pageTitle")}
        description={t("pageDescription")}
        image={metaImage}
      />
      {data && (
        <>
          <Intro title={data.Title} text={data.Intro} media={media} />
          <TriangleContainer
            theme="quaternary-100"
            triangleTheme="quaternary"
            underIntro
          >
            <Title className="col-12 mb-5">{data.QuestionsTitle}</Title>
            <div className="sf-container sf-faq-container col-12">
              <div className="bg-white">
                {data.FAQs.map((item) => (
                  <Faq
                    key={item.id}
                    title={item.Question}
                    body={item.Answer}
                    answerInitial={t("answerInitial")}
                  />
                ))}
              </div>
            </div>
          </TriangleContainer>
        </>
      )}
    </React.Fragment>
  );
};

export default Faqs;

import React, { useEffect, useLayoutEffect, useState } from "react";
import metadataParser from "markdown-yaml-metadata-parser";
import PropTypes from "prop-types";
import classNames from "classnames";
import PlusIcon from "images/plus.svg";
import { useTranslation } from "react-i18next";
import AnimatedLink from "components/shared/AnimatedLink";
import { decodeHtmlString } from "common/utils";
import Article from "./Article";

const ITEMS_PER_PAGE = 5;

const Articles = ({ baseUrl, className, articles }) => {
  const { t, i18n } = useTranslation("resources");
  const [articlesInLanguage, setArticlesInLanguage] = useState(
    (articles && articles[i18n.language]) || []
  );
  const [currentPage, setCurrentPage] = useState(0);
  const [articlesLoaded, setArticlesLoaded] = useState([]);
  const [lastRefresh, setLastRefresh] = useState(0);
  const [seeMoreVisible, setSeeMoreVisible] = useState(true);
  const componentClasses = classNames("articles", "col-12", className);
  const articlesUrl = `/articles/${baseUrl}/${i18n.language}`;
  const imagesUrl = `/articles/${baseUrl}/images`;
  const containerRef = React.createRef();

  const onArticleChange = (articleTitle) => {
    const newArticles = articlesLoaded.map((article) => {
      if (article.title === articleTitle) {
        article.isCollapsed = !article.isCollapsed;
      } else {
        article.isCollapsed = true;
      }
      return {
        ...article,
      };
    });

    setArticlesLoaded(newArticles);
  };

  const loadMore = (e) => {
    if (e) {
      e.preventDefault();
    }

    if (articlesInLanguage.length === articlesLoaded.length) {
      setSeeMoreVisible(false);
      return;
    }

    if (!seeMoreVisible) {
      setSeeMoreVisible(true);
    }

    const articlesToLoad = articlesInLanguage.slice(
      currentPage * ITEMS_PER_PAGE,
      (currentPage + 1) * ITEMS_PER_PAGE
    );

    const promises = articlesToLoad.map((articleFileName) =>
      fetch(`${articlesUrl}/${articleFileName}`).then((response) =>
        response.text()
      )
    );

    Promise.all(promises).then((responses) => {
      const newArticles = responses.map((response) => {
        const result = metadataParser(response);
        const { title } = result.metadata || {};
        const decodedTitle = decodeHtmlString(title);

        return {
          isCollapsed: true,
          ...(result.metadata || {}),
          title: decodedTitle,
          text: result.content || "",
        };
      });
      const newArticlesLoaded = [...articlesLoaded, ...newArticles];
      setArticlesLoaded(newArticlesLoaded);
      if (articlesInLanguage.length === newArticlesLoaded.length) {
        setSeeMoreVisible(false);
      }
    });
  };

  const onLanguageChange = () => {
    setArticlesLoaded([]);
    setArticlesInLanguage((articles && articles[i18n.language]) || []);
    setCurrentPage(0);
    const ts = new Date().getTime();
    setLastRefresh(ts);
  };

  const onClickLoadMore = (e) => {
    e.preventDefault();
    setCurrentPage(currentPage + 1);
  };

  useEffect(() => {
    i18n.on("languageChanged", onLanguageChange);

    return () => {
      i18n.off("languageChanged", onLanguageChange);
    };
  }, [i18n]);

  useEffect(() => {
    loadMore();
  }, [currentPage, lastRefresh]);

  useEffect(() => {
    loadMore();
  }, []);

  useLayoutEffect(() => {
    const container = containerRef.current;
    if (container) {
      const currentOpen = container.querySelector("article.open");
      if (currentOpen) {
        currentOpen.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [containerRef, articlesLoaded]);

  return (
    <article className={componentClasses} ref={containerRef}>
      {articlesLoaded.map((article) => {
        const {
          title,
          text,
          date,
          author,
          image,
          isCollapsed = true,
        } = article;

        return (
          <Article
            key={title}
            title={title}
            date={date}
            author={author}
            image={`${imagesUrl}/${image}`}
            text={text}
            isCollapsed={isCollapsed}
            onChange={onArticleChange}
          />
        );
      })}
      {seeMoreVisible && (
        <div className="see-more text-center">
          <AnimatedLink type="button" noAnimate onClick={onClickLoadMore}>
            {t("seeMore")}
            <img className="plus-icon" src={PlusIcon} alt="" />
          </AnimatedLink>
        </div>
      )}
    </article>
  );
};

Articles.defaultProps = {
  className: null,
  articles: {},
};

Articles.propTypes = {
  baseUrl: PropTypes.string.isRequired,
  className: PropTypes.string,
  articles: PropTypes.object,
};

export default Articles;
